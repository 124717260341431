import cashbackSportPreview from './assets/cashback-sport-preview.jpg';
import cashbackSportBanner from './assets/cashback-sport-banner.jpg';
import cashbackCasinoPreview from './assets/cashback-casino-preview.jpg';
import cashbackCasinoBanner from './assets/cashback-casino-banner.jpg';
// import giveawayBanner from './assets/giveawayBanner.jpg';
// import giveawayPreview from './assets/giveawayPreview.jpg';

type BonusItem = {
  id: string;
  title: string;
  description: string;
  preview: string;
  content: string;
};

export const bonuses: BonusItem[] = [
  {
    id: '2',
    title: 'Кэшбэк на ставки',
    description: 'Отыгрыш кэшбэка отсутствует - денежные средства, полученные как кэшбэк доступны для игры и вывода средств!',
    preview: cashbackSportPreview,
    content: `
        <h1>Кэшбэк на ставки</h1>
        <p><img src=${cashbackSportBanner} /></p>
        <h2>Кэшбэк начисляется на реальный баланс игрока</h2>
        <p>
          Кэшбэк на спортивные ставки начисляется в размере <b>3%</b> за каждую проигранную ставку. Накопленный кэшбэк зачисляется с бонусного баланса на основной счет раз в неделю с четверга на пятницу 00:01 МСК!
          Отыгрыш кэшбэка отсутствует - денежные средства, полученные как кэшбэк на основной счет доступны для игры и вывода средств!
        </p>
    `,
  },
  {
    id: '3',
    title: 'Кэшбэк на слоты и казино',
    description: 'Отыгрыш кэшбэка отсутствует - денежные средства, полученные как кэшбэк доступны для игры и вывода средств!',
    preview: cashbackCasinoPreview,
    content: `
        <h1>Кэшбэк на слоты и казино</h1>
        <p><img src=${cashbackCasinoBanner} /></p>
        <h2>Кэшбэк за слоты и казино начисляется на реальный баланс игрока</h2>
        <p>
          Кэшбэк за слоты и казино начисляется в размере 1% за каждый проигранное вращение или ставку. Накопленный кэшбэк зачисляется с бонусного баланса на основной счет раз в неделю с четверга на пятницу 00:01 МСК!
          Отыгрыш кэшбэка отсутствует - денежные средства, полученные как кэшбэк на основной счет доступны для игры и вывода средств!
        </p>
    `,
  },
  {
    id: '4',
    title: 'Фрибет +50% к первому депозиту!',
    description: 'Пополните счет на люблю сумму и получите на счет +50% от суммы первого депозита!',
    preview: '',
    content: `
        <h1>Фрибет +50% к первому депозиту!</h1>
        <p>
          Пополните счет на люблю сумму и получите на счет +50% от суммы первого депозита! После депозита Вам необходимо написать в чат оператору <a href="https://t.me/boompari_com" target="_blank"
          rel="noreferrer">https://t.me/boompari_com</a> и написать «Хочу фрибет», оператор проверит Ваш депозит и зачислит фрибет на счет!
        </p>
        <p>
          Для того, чтобы отыграть фрибет, достаточно проставить всю сумму с учетом фрибета дважды на коэффициент не менее 1.8!
          Не является обязательным ставить всю сумму одной ставкой, вы можете сделать неограниченное количество ставок, главное, чтобы сумма всех проставленных ставок была не менее указанной в данных условиях!
        </p>
    `,
  },
];

export const bonusesUA: BonusItem[] = [
  {
    id: '2',
    title: 'Кешбек на ставки',
    description: 'Відіграш кешбеку відсутній — кошти, отримані як кешбек, доступні для гри йвиведення!',
    preview: cashbackSportPreview,
    content: `
        <h1>Кешбек на ставки</h1>
        <p><img src=${cashbackSportBanner} /></p>
        <h2>Кешбек нараховується на реальний баланс гравця</h2>
        <p>
          Кешбек на спортивні ставки нараховується в розмірі 3% за кожну програну ставку.
          Накопичений кешбек зараховується з бонусного балансу на основний рахунок раз
          на тиждень із четверга на п'ятницю 00:01 за Києвом! Відіграш кешбеку відсутній —
          кошти, отримані як кешбек на основний рахунок, доступні для гри й виведення!
        </p>
    `,
  },
  {
    id: '3',
    title: 'Кешбек на слоти та казино',
    description: 'Відіграшу кешбеку немає — кошти, отримані як кешбек, доступні для гри йвиведення!',
    preview: cashbackCasinoPreview,
    content: `
        <h1>Кешбек на слоти та казино</h1>
        <p><img src=${cashbackCasinoBanner} /></p>
        <h2>Кешбек за слоти та казино нараховується на реальний баланс гравця</h2>
        <p>
          Кешбек за слоти й казино нараховується в розмірі 1% за кожний програний оберт
          або ставку. Накопичений кешбек зараховується з бонусного балансу на основний
          рахунок раз на тиждень із четверга на п'ятницю 00:01 за Києвом! Відіграшу
          кешбеку немає — кошти, отримані як кешбек на основний рахунок, доступні для
          гри й виведення!
        </p>
    `,
  },
  {
    id: '4',
    title: 'Фрібет +50% до першого депозиту!',
    description: 'Поповніть рахунок на будь-яку суму та отримайте на рахунок +50% від суми першого депозиту!',
    preview: '',
    content: `
        <h1>Фрібет +50% до першого депозиту!</h1>
        <p>
          Поповніть рахунок на будь-яку суму та отримайте на рахунок +50% від суми
          першого депозиту! Після внесення депозиту вам необхідно зайти в чат до
          оператора <a href="https://t.me/boompari_com" target="_blank"
          rel="noreferrer">https://t.me/boompari_com</a> та написати «Хочу фрібет», оператор перевірить
          ваш депозит і зарахує фрібет на рахунок!
        </p>
        <p>
          Для того щоби відіграти фрібет, достатньо проставити всю суму з урахуванням
          фрібету двічі на коефіцієнт не менше 1.8! Не обов'язково робити це однією
          ставкою, ви можете зробити необмежену їхню кількість. Головне, щоби сума всіх
          ставок була не меншою, ніж зазначена в даних умовах!
        </p>
    `,
  },
];

export const bonusesEN: BonusItem[] = [
  {
    id: '2',
    title: 'Cashback on bets',
    description: 'There is no wagering of cashback - funds received as cashback are available for play and withdrawal',
    preview: cashbackSportPreview,
    content: `
        <h1>Cashback on bets</h1>
        <p><img src=${cashbackSportBanner} /></p>
        <h2>Cashback for slots and casino is credited to the actual balance of the player</h2>
        <p>
          Cashback on sports bets is accrued at the rate of 3% for each lost bet. Accrued cashback is
          credited from the bonus balance to the main account once a week from Thursday to Friday
          00:01 Moscow time! There is no wagering of the cashback - funds received as cashback to
          the main account are available for play and withdrawal!
        </p>
    `,
  },
  {
    id: '3',
    title: 'Cashback on slots and casino',
    description: 'There is no wagering of cashback - funds received as cashback are available for play andwithdrawal!',
    preview: cashbackCasinoPreview,
    content: `
        <h1>Cashback on slots and casino</h1>
        <p><img src=${cashbackCasinoBanner} /></p>
        <h2>Cashback for slots and casino is credited to the actual balance of the player</h2>
        <p>
          Cashback for slots and casino is credited at the rate of 1% for each lost spin or bet. Accrued
          cashback is credited from the bonus balance to the main account once a week from
          Thursday to Friday 00:01 Moscow time! There is no wagering of the cashback - funds
          received as cashback to the main account are available for play and withdrawal!
        </p>
    `,
  },
  {
    id: '4',
    title: 'Free bet +50% to the first deposit!',
    description: 'Deposit an amount you like and get +50% of your first deposit on your account!',
    preview: '',
    content: `
        <h1>Free bet +50% to the first deposit!</h1>
        <p>
          Deposit the amount you like and get +50% of your
          first deposit on your account! After the deposit you
          need to write in the chat "I want freibet" to the
          operator <a href="https://t.me/boompari_com" target="_blank"
          rel="noreferrer">https://t.me/boompari_com</a>, the operator will check your
          deposit and credit the free bet to your account!
        </p>
        <p>
          In order to play the free bet, it is enough to bet the whole amount including the free bet twice
          at the coefficient of not less than 1.8! It is not obligatory to bet the whole amount in one bet,
          you can make an unlimited number of bets, the main requirement is that the sum of all bets
          placed should not be less than the amount specified in these conditions!
        </p>
    `,
  },
];

export const bonusessListByLang: Record<string, BonusItem[]> = {
  default: bonusesEN,
  'ru': bonuses,
  'en': bonusesEN,
  'ua': bonusesUA,
  'uk': bonusesUA
};
